/**
 * @Drop returns interfaces of the survey elements.
 *
 * @author Ashok S <ashok@travelinsert.com>
 */
import React from "react";
import { PropTypes } from "prop-types";
import Textarea from "react-textarea-autosize";
import { StyledDropZone } from "react-drop-zone";
import "react-drop-zone/dist/styles.css";


import Confirm from "./Confirm";
import "@reach/dialog/styles.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6

import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'
import Snackbar from "components/Snackbar/Snackbar.jsx";

import * as Datetime from 'react-datetime';
import './Drop.css'

 import calanderimg from '../../../../picture/calandar.png'

const style = {
    cursor: 'move',
}
const cardSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index,
        }
    },
}
const cardTarget = {
    hover(props, monitor, component) {
        if (!component) {
            return null
        }
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }
        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex
    },
}

/**
 * Class that controls the Creates the Drop element of DND Library
 */


class Card extends React.Component {
    constructor(props) {
        super(props);
        this.contentEditable = React.createRef();
        this.handleFocus=this.handleFocus.bind(this);
        this.handleFocusOut= this.handleFocusOut.bind(this);
        
        this.state = {
            selecteddrops: [],
            option: [{ id: 1, value: "option 1" }],
            open: false,
            mandatory: false,
            scale_popup: [],
            limitchar: false,
            fieldlabel: "",
            conditions: [],
            selectedFile: null,
            loaded: 0,
            fieldprops:{},
			updatedInfoVal: "",
			      // snackbar props
      msgColor: "info",
      message: "",
      br: false,
      data:'',
      datetime:false
        };
        
    }

    /**
     * Rich text Editor Toolbar control
     *
     */
    modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
        ]
    };

    /**
     * Rich text Editor formats control
     *
     */
    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "background",
        "align",
        "left",
        "center",
        "right"
    ];

    /**
     * Rich text Editor Submit function
     *
     */
    richText = value => {		
        let match = false;
		this.state.updatedInfoVal = value;
		if(value.indexOf('<a href=') > -1)
		{
			let teststr = value.substring(value.indexOf('<a href='));
			let testArr = teststr.split('<a href="');
			for(var i = 0 ; i < testArr.length ; i++)
			{
				if(testArr[i] != "" && !(testArr[i].startsWith('http')))
				{
					match = true;
					this.showNotification('Please add the scheme http:// or https:// to the start of the link ' + testArr[i].substring(0, testArr[i].indexOf('"')), "danger");
				}
			}
		}
		if(match == false)
		{
			let fieldprops = this.state.fieldprops;
			fieldprops.properties.info_text = value;		
			this.setState({ fieldprops });
		}
		//this.props.autosave()
    };

    /**
     * Pre Mount of conditions and old elements
     *
     */
    componentWillMount() {
        let fieldprops = this.props.oldprop;
        fieldprops.question_id = this.props.question_id;
        this.setState({
            open: false,
            conditions: this.props.oldconditions,
            fieldprops
        });
    }

    /**
     * Update State when recieve new properties from parent
     *
     */
    componentWillReceiveProps(nextProps) {
		let rightStatus = nextProps.rightStatus ? nextProps.rightStatus : false;
        let fieldprops = nextProps.labelprop;
        fieldprops.question_id = nextProps.question_id;
        this.setState({
            fieldprops,
            open: rightStatus,
            conditions: nextProps.oldconditions
        }); 
    }

    /**
     * One time functions which call after mounting
     *
     */
    componentDidMount() {
		/*let fieldprops = this.props.oldprop;
        this.setState({
            fieldprops
        });
        this.testfun(); */

    }


   
    handleFocus(event) {
        let fieldprops=this.state.fieldprops;
        let fieldans = fieldprops.properties;
        if(fieldans.question === "Type a question" || fieldans.question === "Type the message" || fieldans.question === "Type Information"){
            fieldans.question=""
        }
       
        console.log(fieldans,fieldans.question,"chkkkkkkkk")

        this.setState({fieldprops:fieldprops });

    //    if(event.target.value === "" ){
    //     // let fieldprops=this.state.fieldprops;
    //     // let fieldans = fieldprops.properties;
    //     fieldans.question ="Type a question"
    //     this.setState({fieldprops: fieldprops });
    //    }
      }

      handleFocusOut(event){

       if(event.target.value === "" ){
        let fieldprops=this.state.fieldprops;       
        let fieldans = fieldprops.properties;
        let message =   fieldprops.type === "info" ? "Type Information" : fieldprops.type === "capture" ? "Type the message" : "Type a question" ;
        fieldans.question = message
        this.setState({fieldprops: fieldprops });
       }
      }
    
	
	downArrow(){
		  return(
		  <span>
		  <i
		  class="fas fa-arrow-down"
		  style={{ marginTop: "-30px",marginRight:"15px" }}
		  onClick={ e => this.downArrowi()}
		/>
		</span>
		  )
	}

	downArrowi(e){
	  this.props.downArrowFunc(this.props.question_id);
	}

	upArrow(){
		return(

		  <span>
		  <i
		  class="fas fa-arrow-up"
		  style={{ marginTop: "-30px",marginRight:"40px" }}
		  onClick={e => this.upArrowi()}
		/>
		  </span>

		) 
	}

	upArrowi(e){
	  this.props.upArrowFunc(this.props.question_id);
	}


    
    /**
     * Validate and control image to base64
     *
     */
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * Manage to update the icons of scale element
     *
     */

    scaleIcon(e, i, x, y) {
        const fieldprops = this.state.fieldprops;
        if (y === "table_image") {
            fieldprops.properties.table_content.table_value[i].image[x].image_id = e;
        } else if (y === "scale_image") {
            fieldprops.properties.scale_content.map((image, index) => {
                image.image_id = e;
            });
        } else if (y === "upload_icon") {
            fieldprops.properties.upload_icon = e;
        } else {
            fieldprops.properties.scale_content[i].image_id = e;
        }
        this.setState({ fieldprops }
		, this.props.autosave()
		);
    }
	
	showNotification = (msg, color) => {
    this.setState({
      message: msg,
      msgColor: color
    });

    let place = "br";
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      3000
    );
  };

    /**
     * send on drop files to validate and set state
     *
     */
    onDrop(e, s, key, file) {

        this.getBase64(file).then(data => {
            const fieldprops = this.state.fieldprops;
            if (e === "parenlabel_image") {
                fieldprops.properties.options[s].label_image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "sublabel_image") {
                fieldprops.properties.options[s].sublabel[key].label_image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "info_image") {
                fieldprops.properties.info_image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "info_video") {
//nothing
            } else if (e === "t_options_image") {
                fieldprops.properties.table_content.table_options[s].image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "t_table_image") {

                fieldprops.properties.table_content.table_value[s].image[key] = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "sliding_ico") {
                fieldprops.properties.scale_content.slide_images[s].image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else if (e === "binary_ico") {
                fieldprops.properties.scale_content[s].image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            } else {
                if (!fieldprops.properties[`${e}`]) {
                    fieldprops.properties[`${e}`] = {};
                }
                fieldprops.properties[`${e}`][`${s}`].image = data;
                const fileTypeCut = file.type.split("/")[1];
                if (this.isImage(fileTypeCut)) {
                    this.setState({ fieldprops });
                }
            }
        });
        this.props.autosave()
    }

    /**
     * Validate file format
     *
     */
    isImage(filename) {
        switch (filename) {
            case "jpg":
            case "jpeg":
            case "gif":
            case "bmp":
            case "png":
                return true;
        }
        return false;
    }

    /**
     * Validate the delete button with conditions
     *
     */
    handleReset = event => {

        let condtions = this.state.conditions;
        event.conditions.map((condition, index) => {
            condtions.map((original, i) => (original.condtion_id === condition.condtion_id ? (original.error = 1) : ""));
        });
        this.setState({
            condtions
        });
        this.props.deleteddrops(this.props.question_id);
    };

    /**
     * Send element ID to parent to handle delete
     *
     */
    handleDelete(e) {
        this.props.deleteddrops(this.props.question_id);
    }

    /**
     * handle updating element value
     *
     */
    updatestatus(e) {
        const result = e.target.value;
        this.setState(
            {
                fieldlabel: result
            },
            this.testfun
        );
    }

    /**
     * updates the props
     *
     */
    updateprops(e, i, index, key) {

       // this.setState({example:e.target.value})

        const evalu = e.target.value;

        const emojis = this.props.emojis;
        // if( i === "question" &&  evalu === ""){   
           
        //         let fieldprops=this.state.fieldprops;
        //         let fieldans = fieldprops.properties;
        //         fieldans.question ="Type a question"
        //         this.setState({fieldprops: fieldprops });       
        //         console.log("testing")      

        // }
        if (i === "label") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.options[index][`${i}`] = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "childlabel") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.options[index].sublabel[key].sublabel = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "t_value") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.table_content.table_value[index].value = evalu;
            this.setState({
                fieldprops
            });
           
        } else if (i === "t_options") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.table_content.table_options[index].value = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "slider_from") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.scale_content.slide_range[0].from = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "slider_to") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.scale_content.slide_range[0].to = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "binary") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.scale_content[index].text = evalu;
            this.setState({
                fieldprops
            });
        } else if (i === "addscale") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.scale_images = [];
            fieldprops.properties.scale_length = evalu;
            this.setState({
                fieldprops
            });
            for (let i = 0; i < evalu; i++) {
                fieldprops.properties.scale_images.push({ id: i, image: "" });
                this.setState({
                    fieldprops
                });
            }
        } else if (i === "inputtypename") {
            let fieldprops = this.state.fieldprops;
            fieldprops.label = evalu;
            this.setState({
                fieldprops
            });

        } else if (i === "addscalecnt") {

            let fieldprops = this.state.fieldprops;
            fieldprops.properties.scale_content = [];
            fieldprops.properties.scale_length = evalu;
            if (evalu > 5) {
                fieldprops.properties.icon_type = "image";
            }
            this.setState({
                fieldprops
            });
            if (fieldprops.properties.icon_type === "emoji") {
	
                if (evalu == 1) {
                    fieldprops.properties.scale_content.push({ id: 0, value: 1, image_id: this.props.emojis[0].image });
                } else if (evalu == 2) {
                    fieldprops.properties.scale_content.push({ id: 0, value: 1, image_id: this.props.emojis[0].image }, { id: 1, value: 2, image_id: this.props.emojis[4].image });
                } else if (evalu == 3) {
                    fieldprops.properties.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[2].image },
                        { id: 2, value: 3, image_id: this.props.emojis[4].image }
                    );
                } else if (evalu == 4) {
                    fieldprops.properties.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[1].image },
                        { id: 2, value: 3, image_id: this.props.emojis[3].image },
                        { id: 3, value: 4, image_id: this.props.emojis[4].image }
                    );
                } else if (evalu == 5) {
                    fieldprops.properties.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[1].image },
                        { id: 2, value: 3, image_id: this.props.emojis[2].image },
                        { id: 3, value: 4, image_id: this.props.emojis[3].image },
                        { id: 4, value: 5, image_id: this.props.emojis[4].image }
                    );
                }
                this.setState({
                    fieldprops
                });
            } else {
                for (let i = 0; i < evalu; i++) {
                    fieldprops.properties.scale_content.push({ id: i, value: i + 1, image_id: "" });
                    this.setState({
                        fieldprops
                    });
                }
            }
        } else if (i === "table_value") {
            let fieldprops = this.state.fieldprops;
            let popup = this.state.scale_popup;
            popup.table_gallery = [];
            fieldprops.properties.table_content.value_length = evalu;
            fieldprops.properties.table_content.table_value = [];
            this.setState({
                fieldprops
            });
            let optionarr = [];
            if (fieldprops.properties.table_content.table_options) {
                optionarr = fieldprops.properties.table_content.table_options.map((opt, y) => {
                    return { id: y, image_id: "" };
                });
            }
            for (let i = 0; i < evalu; i++) {
                let start = i + 1;
                fieldprops.properties.table_content.table_value.push({ id: i, value: "", image: [] });
                popup.table_gallery.push([]);
                //fieldprops.properties.scale_content.table_value[i].image = []
                //fieldprops.properties.scale_content.table_value[i].image.push(optionarr)
                this.setState({
                    fieldprops,
                    popup
                });
            }
            fieldprops.properties.table_content.table_value.map((val, i) => {
                val.image = [];
                if (fieldprops.properties.table_content.table_options) {
                    fieldprops.properties.table_content.table_options.map((opt, y) => {
                        val.image.push({ id: y, image_id: "" });
                    });
                }
            });
            this.setState({
                fieldprops
            });
        } else if (i === "table_options") {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.table_content.options_length = evalu;
            fieldprops.properties.table_content.table_options = [];
            this.setState({
                fieldprops
            });

            for (let i = 0; i < evalu; i++) {
                fieldprops.properties.table_content.table_options.push({ id: i, value: "" });
                this.setState({
                    fieldprops
                });
            }
            fieldprops.properties.table_content.table_value.map((val, i) => {
                val.image = [];
                fieldprops.properties.table_content.table_options.map((opt, y) => {
                    val.image.push({ id: y, image_id: "" });
                });
            });
            this.setState({
                fieldprops
            });
        } else if (i === "barcode_ids") {
            const oldprops = this.state.fieldprops.properties;
            let value = e.target.value;
            let arrayval = value.split(/[\n,]+/);
            oldprops[`${i}`] = arrayval;
            this.setState({ oldprops });
        } else if (i === "icon_type") {
            const oldprops = this.state.fieldprops.properties;
            let value = e.target.value;
            console.log(value,"lllllllll222")
            if (value === "emoji") {
                if (!oldprops.scale_content) {
                    oldprops.scale_content = [];
                }
                if (oldprops.scale_content.length == 1) {
                    oldprops.scale_content = [];
                    oldprops.scale_content.push({ id: 0, value: 1, image_id: this.props.emojis[0].image });
                } else if (oldprops.scale_content.length == 2) {
                    oldprops.scale_content = [];
                    oldprops.scale_content.push({ id: 0, value: 1, image_id: this.props.emojis[0].image }, { id: 1, value: 2, image_id: this.props.emojis[4].image });
                } else if (oldprops.scale_content.length == 3) {
                    oldprops.scale_content = [];
                    oldprops.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[2].image },
                        { id: 2, value: 3, image_id: this.props.emojis[4].image }
                    );
                } else if (oldprops.scale_content.length == 4) {
                    oldprops.scale_content = [];
                    oldprops.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[1].image },
                        { id: 2, value: 3, image_id: this.props.emojis[3].image },
                        { id: 3, value: 4, image_id: this.props.emojis[4].image }
                    );
                } else if (oldprops.scale_content.length == 5) {
                    oldprops.scale_content = [];
                    oldprops.scale_content.push(
                        { id: 0, value: 1, image_id: this.props.emojis[0].image },
                        { id: 1, value: 2, image_id: this.props.emojis[1].image },
                        { id: 2, value: 3, image_id: this.props.emojis[2].image },
                        { id: 3, value: 4, image_id: this.props.emojis[3].image },
                        { id: 4, value: 5, image_id: this.props.emojis[4].image }
                    );
                }
                oldprops[`${i}`] = value;
            } else {
                oldprops[`${i}`] = value;
                oldprops.scale_content.map((empty, index) => {
                    empty.image_id = "";
                });
            }
            this.setState({ oldprops });
        } else {
            const oldprops = this.state.fieldprops.properties;           
            let type = this.state.fieldprops.type;
            let message =   type === "info" ? "Type Information" : type === "capture" ? "Type the message" : "Type a question" ;
            // let value = e.target.value === "" ? message : e.target.value;       
            let value = e.target.value;       
            if (i === "minimum" || i === "maximum" || i === "addscale")
             value = parseInt(value);
            oldprops[`${i}`] = value;
            this.setState({ oldprops });
        }
        this.props.autosave()
    }

    /**
     * Gallery Popup Function
     *
     */
    localGallery(i, y, x) {

        let scale_popup = this.state.scale_popup;
        if (y === "table_gallery") {

            if (!scale_popup[`${y}`]) {
                scale_popup[`${y}`] = [];

            }
            if (!scale_popup[`${y}`][i]) {

                scale_popup[`${y}`][i] = [];
            }
            scale_popup[`${y}`][i][x] = "enabled";
        } else {
            if (!scale_popup[`${y}`]) {
                scale_popup[`${y}`] = [];
            }
            scale_popup[`${y}`][i] = "enabled";
        }

        this.setState({
            scale_popup
        });
    }

    /**
     * Gallery close function
     *
     */
    hideGallery(i, y, x) {
        let scale_popup = this.state.scale_popup;
        if (y === "table_gallery") {
            scale_popup[`${y}`][i][x] = "disabled";
        } else {
            scale_popup[`${y}`][i] = "disabled";
        }
        this.setState({
            scale_popup
        });
    }

    /**
     * handle radio button event
     *
     */
    radioChange = event => {

        let fieldprops = this.state.fieldprops;
        fieldprops.properties.choice_type = event.target.value;
        this.setState({
            fieldprops
        }, this.props.autosave()
		);
    };

    /**
     * Handle radio funtions
     *
     */
    radioFunction = event => {

        let evalu = event.target.value;
        let fieldprops = this.state.fieldprops;
        if (evalu === "sliding") {
            fieldprops.properties[`${event.target.name}`] = evalu;
            fieldprops.properties.scale_content = {
                slide_range: [{ from: "", to: "" }],
                slide_images: [{ id: 0, image: "" }, { id: 1, image: "" }, { id: 2, image: "" }]
            };
        } else if (evalu === "binary") {
            fieldprops.properties[`${event.target.name}`] = evalu;
            fieldprops.properties.scale_content = [{ id: 0, text: "", image: "" }, { id: 1, text: " ", image: "" }];
        } else if (evalu === "scale") {
            if (fieldprops.properties.grid_type) {

                delete fieldprops.properties.grid_type;
            }
            if (fieldprops.properties.table_content) {
                delete fieldprops.properties.table_content;
            }
            fieldprops.properties[`${event.target.name}`] = evalu;
            fieldprops.properties.icon_type = "image";
        } else if (evalu === "table") {
            if (fieldprops.properties.start_text) {
                delete fieldprops.properties.start_text;
            }
            if (fieldprops.properties.end_text) {
                delete fieldprops.properties.end_text;
            }
            if (fieldprops.properties.scale_content) {
                delete fieldprops.properties.scale_content;
            }
            fieldprops.properties[`${event.target.name}`] = evalu;
            fieldprops.properties.grid_type = "image";
            fieldprops.properties.table_content = {};
        } else {
            fieldprops.properties[`${event.target.name}`] = evalu;
            fieldprops.properties.scale_content = [];
        }
        this.setState({
            fieldprops
        }, this.props.autosave()
		);
    };

    /**
     * callback function call of attributes
     *
     */
    testfun() {
        this.props.attrib(this.state.fieldlabel, this.props.vid);
    }

    /**
     * callback function call of fieldprops
     *
     */
    handleClick() {
        const newval = this.state.option.length + 1;
        this.setState({
            option: [...this.state.option, { id: newval, value: "option " + newval }]
        });
    }

    /**
     * handle element open function
     *
     */
    handleClickOpen = e => {
        const { question_id } = this.props;
        this.setState({ open: true }, this.props.rightOpen(e, true, question_id));
    };

    /**
     * handle element close function
     *
     */
    handleClose = e => {
        const { question_id } = this.props;
        this.setState({ open: false }, this.props.rightOpen(e, false, question_id));
    };

    /**
     * handle element callback function
     *
     */
    handleChange = name => event => {
        let checkval;
        event.target.checked === true ? (checkval = 1) : (checkval = 0);
        if (name === "limitchar" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            fieldprops["properties"]["minimum"] = 0;
            fieldprops["properties"]["maximum"] = 100;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "limitchar" && checkval === 0) {
            let fieldprops = this.state.fieldprops;
            fieldprops["properties"]["minimum"] = "";
            fieldprops["properties"]["maximum"] = "";
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "instruction_enabled" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "instruction_enabled" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "barcode_enabled" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "barcode_enabled" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "text_info" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            fieldprops.properties.font_style = "normal";
            fieldprops.properties.text_align = "left";
            fieldprops.properties.text_position = "top";
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "text_info" && checkval === 0) {
            let fieldprops = this.state.fieldprops;
            delete fieldprops.properties.font_style;
            delete fieldprops.properties.text_align;
            delete fieldprops.properties.text_position;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "marker_enabled" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "marker_enabled" && checkval === 0) {
            let fieldprops = this.state.fieldprops;
            if (fieldprops.properties.instruction_enabled) {
                fieldprops.properties.instruction_enabled = 0;
            }
            if (fieldprops.properties.instruction_text) {
                delete fieldprops.properties.instruction_text;
            }
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "scale_enabled" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "scale_enabled" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "other" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "other" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        if (name === "allow_gallery" && checkval === 1) {
            let fieldprops = this.state.fieldprops;
            this.setState(
                {
                    [name]: checkval,
                    fieldprops
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "allow_gallery" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "mandatory" && checkval === 1) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "mandatory" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "noreturn" && checkval === 1) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "noreturn" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "multilevel" && checkval === 1) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        } else if (name === "multilevel" && checkval === 0) {
            this.setState(
                {
                    [name]: checkval
                },
                () => {
                    this.updatepropschecked(checkval, name);
                }
            );
        }
        this.props.autosave()
    };

    /**
     * update props function
     *
     */
    updatepropschecked(e, i) {
        const oldprops = this.state.fieldprops.properties;
        oldprops[`${i}`] = e;
        this.setState({ oldprops });
    }

    /**
     * adds new element
     *
     */
    addfun(e, index) {
this.setState({value:1})
        const fieldprops = this.state.fieldprops;

        if (e === "suboptions") {
            let manual = fieldprops.properties.options[index].sublabel ? fieldprops.properties.options[index].sublabel.length : 0;
            if (!fieldprops.properties.options[index].sublabel || !(fieldprops.properties.options[index].sublabel instanceof Array)) {
                fieldprops.properties.options[index].sublabel = [];
            }
            fieldprops.properties.options[index].sublabel.push({ id: manual, sublabel: "", label_image: "" });
            this.setState({ fieldprops });
        } else {
            let manual = fieldprops.properties[`${e}`] ? fieldprops.properties[`${e}`].length : 0;
            if (!fieldprops.properties[`${e}`]) {
                fieldprops.properties[`${e}`] = [];
            }
            fieldprops.properties[`${e}`].push({ id: manual, label: "", label_image: "" });
            this.setState({ fieldprops });


        }
    }
    /**
     * removes the element
     *
     */
    deletefun(key, type, subkey) {
        let fieldprops = this.state.fieldprops;

        if (type === "childlabel") {
            //delete fieldprops.properties.options[key].sublabel[subkey]
            fieldprops.properties.options[key].sublabel.splice(subkey, 1);
            this.setState({ fieldprops });
        } else if (type === "parentlabel") {
            //delete fieldprops.properties.options[key]
            let newFieldProps = fieldprops.properties.options.splice(key, 1);

            this.setState({ fieldprops });
        }
    }

    /**
     * Validate conditions and alert when delete
     *
     */
    deleteAlert = () => {
        const conditions = this.props.oldconditions;
        let check = conditions.filter(condition => {
            return (
                condition.source.handler === this.state.fieldprops.handler ||
                condition.source.matchid === this.state.fieldprops.handler ||
                condition.target.handler === this.state.fieldprops.handler
            );
        });

        let rbo = this.state.fieldprops;
        return (
            <span>
        {conditions.length === 0 ? (
            <span>
            <i className="fa fa-trash" onClick={e => this.handleDelete()} />
          </span>
        ) : (
            <span>
            {check.length > 0 ? (
                <span>
                <Confirm title={"delete" + " " + this.props.labelprop.label} description="The Element is Assosiated with Condtions. Are you sure want to delete ?">
                  {confirm => <i className="fa fa-trash" onClick={confirm(() => this.handleReset(this.state.fieldprops))} />}
                </Confirm>
              </span>
            ) : (
                <span>
                <i className="fa fa-trash" onClick={e => this.handleDelete()} />
              </span>
            )}
          </span>
        )}
      </span>
        );
    };

    /**
     * Check video file formats
     *
     */
    isVideo(filename) {
		switch (filename) {
            case "jpeg":
            case "gif":
            case "png":
            case "mp4":
            case "mov":
            case "mp3":
                return true;
        }
        return false;
    }

    /**
     * video file process
     *
     */
    handleselectedFile = event => {

        let filename = event.target.files[0].name;
		let filenameArr = filename.split(".");
        const fileTypeCut = filenameArr[filenameArr.length - 1];

        if (this.isVideo(fileTypeCut)) {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0
            });
        }
    };

    /**
     * video file upload
     *
     */
    handleUpload = name => {
        // const data = new FormData();
        // data.append("file", this.state.selectedFile, this.state.selectedFile.name);

        // api2
        //     .post("v1/gallery/video", data, {
        //         onUploadProgress: ProgressEvent => {
        //             this.setState({
        //                 loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
        //             });
        //         }
        //     })
        //     .then(resp => {

        //         let fieldprops = this.state.fieldprops;
        //         fieldprops.properties[`${name}`] = resp.data.url;
        //         this.setState({
        //             selectedFile: null,
        //             loaded: 0,
        //             fieldprops
        //         }, this.props.autosave()
		// 		);
        //     })
        //     .catch(error => {

        //         if (error.response) {
        //             console.log(error.response.data);
        //             console.log(error.response.status);
        //             console.log(error.response.headers);
        //         } else if (error.request) {
        //             console.log(error.request);
        //         } else {
        //             console.log("Error", error.message);
        //         }
        //         console.log(error.config);
        //     });
    };

    activateLasers = (name) => {

        let fieldprops = this.state.fieldprops;
        if (name === "image") {
            fieldprops.properties.info_image = ""
        }
        else if (name === "video") {
            fieldprops.properties.info_video = ""
        }
        else if (name === "audio") {
            fieldprops.properties.info_audio = ""
        }
        this.setState({ fieldprops }, this.props.autosave()
		);

    }


    deleteTag = (index) => {

        let fieldprops = this.state.fieldprops;
        fieldprops.properties.scale_images[index].image = ""
        this.setState({ fieldprops }, this.props.autosave()
		);
    }
render() {

		const gallery = this.props.gallery;
        const emojis = this.props.emojis;
        const infoico = this.props.infoico;
        const scaleico = this.props.scaleico;
        const infoicon = gallery.concat(infoico)
        const scaleicon = gallery.concat(scaleico)
        const fieldprops = this.props.fieldprops;
        const { open } = this.state;
        const city = this.state.fieldlabel;
        const marker = this.state.fieldprops.properties.marker;
        const scale_images = this.state.fieldprops.properties.scale_images;
        const scale_content = this.state.fieldprops.properties.scale_content;
        const table_content = this.state.fieldprops.properties.table_content;
        const scale_length = scale_content ? scale_content.length : 0;
        const scale_len = scale_images ? scale_images.length : "";
        const boardval = open ? "properties-mmain-tab openbox" : "properties-mmain-tab closebox";
        let alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        const labelpropere = city ? city : "Type a Question";
        const widthdef = this.state.fieldprops.chars ? this.state.fieldprops.chars : 20;
        const mindef = this.state.fieldprops.minentry ? city : 0;
        const maxdef = this.state.fieldprops.maxentry ? city : 100;
        const {msgColor, br, message} = this.state;
        console.log(this.state.fieldprops,"gokiiiiiiiii")

        
        const inputbox = (
            <div>
                <h4 className="labelheading">{this.state.fieldprops.label}</h4>
                <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                    <div className="topbar">
                        <div className="label">
                            <Textarea minRows={1} maxRows={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" 
                            style={{ resize:"none" }}/>
                            {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                        {/* {this.upArrow()}
						{this.downArrow()} */}
						</div>
                        <div className="actions">
              <span onClick={() => this.handleClickOpen("open2")}>
                <i className="fa fa-cog" />
              </span>

                            <div className={boardval}>
                                <div className="properties-main-header clear">
                                    <p>Text Input Properties</p>
                                    <i class="fa fa-close" onClick={() => this.handleClose("open1")} />
                                </div>
                                <div className="properties-body-tab">
                                    <ul>
                                        <li>
                                            <h3>Question Text</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />
                                                <label> Type your questions </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Name</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                                <label> Edit your Name </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Sub Heading</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                                <label> Add a sub heading below main title. </label>
                                            </div>
                                        </li>

                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                    </div>
                                                </div>
                                                <label> Prevent submission if this question is empty. </label>
                                            </div>
                                        </li>
										
										<li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">No Return</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Validation</h3>
                                            <div className="below-lanbel-body">
                                                <select onChange={e => this.updateprops(e, "content_type")}>
                                                    <option selected={this.state.fieldprops.properties.content_type === "text" ? "selected" : ""} value="text">
                                                        None
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "alphabets" ? "selected" : ""} value="alphabets">
                                                        Alphabetic
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "alphanumeric" ? "selected" : ""} value="alphanumeric">
                                                        AlphaNumeric
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "email" ? "selected" : ""} value="email">
                                                        Email
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "number" ? "selected" : ""} value="number">
                                                        Numeric
                                                    </option>
                                                    <label />
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">
                                                        Limit Entry <span>(Chars) </span>
                                                    </div>
                                                    <div class="switches-boxes">
                                                        <Switchlimittwo checked={this.state.fieldprops.properties.limitchar} onChange={this.handleChange("limitchar")} value="limitchar" color="primary" />
                                                    </div>
                                                </div>
                                                {this.state.fieldprops.properties.limitchar ? (
                                                    <div className="twocol">
                                                        <div class="switch-textboxes">
                                                            <label>MIN </label>
                                                            <input type="number" name="name" value={this.state.fieldprops.properties.minimum} onChange={e => this.updateprops(e, "minimum")} />
                                                        </div>
                                                        <div class="switch-textboxes">
                                                            <label>MAX </label>
                                                            <input type="number" name="name" value={this.state.fieldprops.properties.maximum} onChange={e => this.updateprops(e, "maximum")} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    false
                                                )}

                                                <label>Limit the maximum number of characters allowed for this field. </label>
                                            </div>
                                        </li>
                                        <li>
                                            <h3>Sub Label</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.sublabel} class="mediumfm" onChange={e => this.updateprops(e, "sublabel")} />
                                                <label> Add a small description below the input field. </label>
                                            </div>
                                        </li> */}
                                        {/* <li>
                                <h3>Placeholder</h3>
                                <div className="below-lanbel-body">
                                    <input type="text" name="name" value={this.state.fieldprops.placeholder} class="mediumfm" onChange={(e) => this.updateprops(e, 'placeholder')} />
                                    <label>Add an example hint inside the field. </label>
                                </div>
                            </li> */}
                                    </ul>
                                </div>
                            </div>
                            {this.deleteAlert()}
                        </div>
                    </div>
                    <div className="field">
                        <input type="text" name="name" disabled size={this.state.fieldprops.properties.length} />
                        <p className="flabel">{this.state.fieldprops.properties.sublabel}</p>
                    </div>
                </div>
            </div>
        );
        
        
        const date = (
            <div>
                <h4 className="labelheading">{this.state.fieldprops.label}</h4>
                <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                    <div className="topbar">
                        <div className="label">
                            <Textarea minRows={1} maxRows={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" 
                            style={{ resize:"none" }}/>
                            {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                        {/* {this.upArrow()}
						{this.downArrow()} */}
						</div>
                        <div className="actions">
              <span onClick={() => this.handleClickOpen("open2")}>
                <i className="fa fa-cog" />
              </span>

                            <div className={boardval}>
                                <div className="properties-main-header clear">
                                    <p>Date Input Properties</p>
                                    <i class="fa fa-close" onClick={() => this.handleClose("open1")} />
                                </div>
                                <div className="properties-body-tab">
                                    <ul>
                                        <li>
                                            <h3>Question Text</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />
                                                <label> Type your questions </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Name</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                                <label> Edit your Name </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Sub Heading</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                                <label> Add a sub heading below main title. </label>
                                            </div>
                                        </li>

                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                    </div>
                                                </div>
                                                <label> Prevent submission if this question is empty. </label>
                                            </div>
                                        </li>
										
										<li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">No Return</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Validation</h3>
                                            <div className="below-lanbel-body">
                                                <select onChange={e => this.updateprops(e, "content_type")}>
                                                    <option selected={this.state.fieldprops.properties.content_type === "text" ? "selected" : ""} value="text">
                                                        None
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "alphabets" ? "selected" : ""} value="alphabets">
                                                        Alphabetic
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "alphanumeric" ? "selected" : ""} value="alphanumeric">
                                                        AlphaNumeric
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "email" ? "selected" : ""} value="email">
                                                        Email
                                                    </option>
                                                    <option selected={this.state.fieldprops.properties.content_type === "number" ? "selected" : ""} value="number">
                                                        Numeric
                                                    </option>
                                                    <label />
                                                </select>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">
                                                        Limit Entry <span>(Chars) </span>
                                                    </div>
                                                    <div class="switches-boxes">
                                                        <Switchlimittwo checked={this.state.fieldprops.properties.limitchar} onChange={this.handleChange("limitchar")} value="limitchar" color="primary" />
                                                    </div>
                                                </div>
                                                {this.state.fieldprops.properties.limitchar ? (
                                                    <div className="twocol">
                                                        <div class="switch-textboxes">
                                                            <label>MIN </label>
                                                            <input type="number" name="name" value={this.state.fieldprops.properties.minimum} onChange={e => this.updateprops(e, "minimum")} />
                                                        </div>
                                                        <div class="switch-textboxes">
                                                            <label>MAX </label>
                                                            <input type="number" name="name" value={this.state.fieldprops.properties.maximum} onChange={e => this.updateprops(e, "maximum")} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    false
                                                )}

                                                <label>Limit the maximum number of characters allowed for this field. </label>
                                            </div>
                                        </li>
                                        <li>
                                            <h3>Sub Label</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.sublabel} class="mediumfm" onChange={e => this.updateprops(e, "sublabel")} />
                                                <label> Add a small description below the input field. </label>
                                            </div>
                                        </li> */}
                                        {/* <li>
                                <h3>Placeholder</h3>
                                <div className="below-lanbel-body">
                                    <input type="text" name="name" value={this.state.fieldprops.placeholder} class="mediumfm" onChange={(e) => this.updateprops(e, 'placeholder')} />
                                    <label>Add an example hint inside the field. </label>
                                </div>
                            </li> */}
                                    </ul>
                                </div>
                            </div>
                            {this.deleteAlert()}
                        </div>
                    </div>
                    <div className="field" style={{padding:"1%"}}>
                         {/* <input type="text" name="name" disabled size={this.state.fieldprops.properties.length} /> */}
                        {/* <p className="flabel">{this.state.fieldprops.properties.sublabel}</p> */}
<div style={{display:"flex",width:"100%"}}>
    <Datetime 
style={{position:'absolute'}}
//  ref={input => this.datetime = input}
//  open={this.state.datetime}
//  disableCloseOnClickOutside={false}
//  onBlur={()=>this.setState({datetime:false})}
//  onFocus={()=>this.setState({datetime:true})}
/> 
</div>
<div style={{display:"flex",width:"100%"}}>
<img src={calanderimg} style={{float:'right',flexDirection:"row",marginTop: "-38px",marginLeft:"194px",height:'38px',position:'static', width: "42px", border: "1px solid #ced4da", background: "#ececec", padding: "4px 8px"}} 
 onClick={()=>{this.setState({datetime:true})}}></img>   
</div>

                       </div>
                </div>
            </div>
        );
        
        
        
        const singleselect = (
            <div>
                <h4 className="labelheading">{this.state.fieldprops.label}</h4>
                <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                    <div className="topbar">
                        <div className="label">
        <Textarea minRows={1} maxRows={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" style={{ resize:"none" }} />
                            {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                        {/* {this.upArrow()}
                        {this.downArrow()} */}
                        <div style={{display:'flex',width:'100%'}}>
                        <h6 style={{flexDirection:"row",top: "14px",right:"13%", position: 'absolute'}}>Weight</h6>
                       </div>
                         </div>
                        <div className="actions">
              <span onClick={() => this.handleClickOpen("open1")}>
                <i className="fa fa-cog" />
              </span>

                            <div className={boardval}>
                                <div className="properties-main-header clear">
                                    <p>Single Select question Properties</p>
                                    <i class="fa fa-close" onClick={() => this.handleClose("open1")} />
                                </div>
                                <div className="properties-body-tab">
                                    <ul>
                                        <li>
                                            <h3>Question Text</h3>
                                             <div className="below-lanbel-body">
                                             <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />                                               <label> Type your questions </label>
                                            </div>
                                        </li>
                                        <li>
                                        <h3>Name</h3>
                                        <div className="below-lanbel-body">
                                            <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                            <label> Edit your Name </label>
                                        </div>
                                    </li>

                                        <li>
                                            <h3>Sub Heading</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                                <label> Add a sub heading below main title. </label>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                    </div>
                                                </div>
                                                <label> Prevent submission if this question is empty. </label>
                                            </div>
                                        </li>
										 */}
										{/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">No Return</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Display Other Option</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.other} onChange={this.handleChange("other")} value="other" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div className="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Choice Type</h3>
                                                    <div className="twocol">
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="single" checked={this.state.fieldprops.properties.choice_type === "single"} onChange={this.radioChange} />
                                                                <label>Single</label>
                                                            </div>
                                                        </div>
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="multiple" checked={this.state.fieldprops.properties.choice_type === "multiple"} onChange={this.radioChange} />
                                                                <label>Multiple</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Options</h3>

                                                    {/* <div class="switch-textboxes xtboxestext">Multilevel</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimittwo checked={this.state.fieldprops.properties.multilevel} onChange={this.handleChange("multilevel")} value="multilevel" color="primary" />
                                                    </div> */}
                                                    <div className="clearfix" />
                                                    {this.state.fieldprops.properties.options
                                                        ? this.state.fieldprops.properties.options.map(
                                                            function(value, index) {
                                                                return (
                                                                    <div className="twocol dropboxer">
                                                                        <div className="dropper dropper-aletr-div">
                                                                            <div className="parentlabel clear clearfix">
                                                                                <span>{alphabet[index]}</span>
                                                                                <input
                                                                                    type="text"
                                                                                    name="name"
                                                                                    class="mediumfm"
                                                                                    value={this.state.fieldprops.properties.options[index].label}
                                                                                    onChange={e => this.updateprops(e, "label", index)}
                                                                                />

                                                                                <div className="wrap-upload-delete">
                                                                                    <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "parenlabel_image", index, "")} />
                                                                                    <div className="addimgs">
                                                                                        <input></input>
                                                                                        <i class="fa fa-trash" onClick={() => this.deletefun(index, "parentlabel")} />
                                                                                    </div>
                                                                                    <img src={value.label_image} width="50" />
                                                                                </div>
                                                                            </div>

                                                                            {this.state.fieldprops.properties.multilevel === 1 ? (
                                                                                <div className="sublabel">
                                                                           
                                                                                    {this.state.fieldprops.properties.options && this.state.fieldprops.properties.options[index].sublabel &&
																					this.state.fieldprops.properties.options[index].sublabel instanceof Array
                                                                                        ? this.state.fieldprops.properties.options[index].sublabel.map(
                                                                                            function(value, key) {
                                                                                                return (
                                                                                                    <div className="clear clearfix">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="name"
                                                                                                            class="mediumfm"
                                                                                                            value={this.state.fieldprops.properties.options[index].sublabel[key].sublabel}
                                                                                                            onChange={e => this.updateprops(e, "childlabel", index, key)}
                                                                                                        />

                                                                                                        <div className="wrap-upload-delete">
                                                                                                            <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "sublabel_image", index, key)} />
                                                                                                            <div className="addimgs">
                                                                                                                <i class="fa fa-trash" onClick={() => this.deletefun(index, "childlabel", key)} />
                                                                                                            </div>
                                                                                                            <img src={value.label_image} width="50" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            }.bind(this)
                                                                                        )
                                                                                        : 																						
																						""}

                                                                                    <div className="addmoreimage" onClick={() => this.addfun("suboptions", index)}>
                                                                                        {" "}
                                                                                        <i className="fa fa-plus" /> Add{" "}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }.bind(this)
                                                        )
                                                        : ""}
                                                    <div className="options">
                                                        <div className="addmoreimage addmoreimage-big" onClick={() => this.addfun("options")}>
                                                            {" "}
                                                            <i className="fa fa-plus" /> Add{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {this.deleteAlert()}
                        </div>
                    </div>
                    <div className="field">
                        <div className="option option-checkbox">
                            {this.state.fieldprops.properties.options ? (
                                <ul className="clear">
                                    {" "}
                                    {this.state.fieldprops.properties.options.map(
                                        function(value, index) {
                                            return (
                                                <li>
                                                    {" "}
                                                    {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="radio" />}{" "}
                                                   
                                                    <img src={value.label_image} />  {value.label}

                                                
                                                    <input type='text' style={{marginRight:'10%',float:'right',height:24,width:80, marginTop: "9px" }}></input>
                                                    
                                                    <div className="parent-of-child-class clear">
                                                        {value.sublabel && value.sublabel instanceof Array
                                                            ? value.sublabel.map(
                                                                function(subval, key) {
                                                                    return (
                                                                        <div>
                                                                            {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="radio" />}{" "}
                                                                            <img src={subval.label_image} /> {subval.sublabel}
                                                                        </div>
                                                                    );
                                                                }.bind(this)
                                                            )
                                                            : ""}
                                                    </div>
                                                </li>
                                            );
                                        }.bind(this)
                                    )}
                                    {this.state.fieldprops.properties.other === 1 ? (
                                        <li>
                                            {this.state.fieldprops.properties.choice_type === "single" ? <input name="choice" type="radio" /> : <input type="checkbox" />}
                                            Others
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );

        const multipleselect = (
            <div>
                <h4 className="labelheading">{this.state.fieldprops.label}</h4>
                <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                    <div className="topbar">
                        <div className="label">
                            <Textarea minRows={1} maxRows={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" style={{ resize:"none" }} />
                            {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                      
                            
                        {/* {this.upArrow()}
                        {this.downArrow()} */}
                        <div style={{display:'flex',width:'100%'}}>
                        <h6 style={{flexDirection:"row",top: "14px",right:"13%", position: 'absolute'}}>Weight</h6>
                        </div>
                        </div>
                        <div className="actions">
              <span onClick={() => this.handleClickOpen("open2")}>
                <i className="fa fa-cog" />
              </span>

                            <div className={boardval}>
                                <div className="properties-main-header clear">
                                    <p>Muliple Select question Properties</p>
                                    <i class="fa fa-close" onClick={() => this.handleClose("open1")} />
                                </div>
                                <div className="properties-body-tab">
                                    <ul>
                                        <li>
                                            <h3>Question Text</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />
                                                <label> Type your questions </label>
                                            </div>
                                        </li>
                                        <li>
                                            <h3>Name</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                                <label> Edit your Name </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Sub Heading</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                                <label> Add a sub heading below main title. </label>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                    </div>
                                                </div>
                                                <label> Prevent submission if this question is empty. </label>
                                            </div>
                                        </li>
										 */}
										{/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">No Return</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Display Other Option</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.other} onChange={this.handleChange("other")} value="other" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div className="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Choice Type</h3>
                                                    <div className="twocol">
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="single" checked={this.state.fieldprops.properties.choice_type === "single"} onChange={this.radioChange} />
                                                                <label>Single</label>
                                                            </div>
                                                        </div>
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="multiple" checked={this.state.fieldprops.properties.choice_type === "multiple"} onChange={this.radioChange} />
                                                                <label>Multiple</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Options</h3>

                                                    {/* <div class="switch-textboxes xtboxestext">Multilevel</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimittwo checked={this.state.fieldprops.properties.multilevel} onChange={this.handleChange("multilevel")} value="multilevel" color="primary" />
                                                    </div> */}
                                                    <div className="clearfix" />
                                                    {this.state.fieldprops.properties.options
                                                        ? this.state.fieldprops.properties.options.map(
                                                            function(value, index) {
                                                                return (
                                                                    <div className="twocol dropboxer">
                                                                        <div className="dropper dropper-aletr-div">
                                                                            <div className="parentlabel clear clearfix">
                                                                                <span>{alphabet[index]}</span>
                                                                                <input
                                                                                    type="text"
                                                                                    name="name"
                                                                                    class="mediumfm"
                                                                                    value={this.state.fieldprops.properties.options[index].label}
                                                                                    onChange={e => this.updateprops(e, "label", index)}
                                                                                />

                                                                                <div className="wrap-upload-delete">
                                                                                    <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "parenlabel_image", index, "")} />
                                                                                    <div className="addimgs">
                                                                                        <i class="fa fa-trash" onClick={() => this.deletefun(index, "parentlabel")} />
                                                                                    </div>
                                                                                    <img src={value.label_image} width="50" />
                                                                                </div>
                                                                            </div>

                                                                            {this.state.fieldprops.properties.multilevel === 1 ? (
                                                                                <div className="sublabel">
                                                                           
                                                                                    {this.state.fieldprops.properties.options && this.state.fieldprops.properties.options[index].sublabel &&
																					this.state.fieldprops.properties.options[index].sublabel instanceof Array
                                                                                        ? this.state.fieldprops.properties.options[index].sublabel.map(
                                                                                            function(value, key) {
                                                                                                return (
                                                                                                    <div className="clear clearfix">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="name"
                                                                                                            class="mediumfm"
                                                                                                            value={this.state.fieldprops.properties.options[index].sublabel[key].sublabel}
                                                                                                            onChange={e => this.updateprops(e, "childlabel", index, key)}
                                                                                                        />

                                                                                                        <div className="wrap-upload-delete">
                                                                                                            <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "sublabel_image", index, key)} />
                                                                                                            <div className="addimgs">
                                                                                                                <i class="fa fa-trash" onClick={() => this.deletefun(index, "childlabel", key)} />
                                                                                                            </div>
                                                                                                            <img src={value.label_image} width="50" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            }.bind(this)
                                                                                        )
                                                                                        : 																						
																						""}

                                                                                    <div className="addmoreimage" onClick={() => this.addfun("suboptions", index)}>
                                                                                        {" "}
                                                                                        <i className="fa fa-plus" /> Add{" "}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }.bind(this)
                                                        )
                                                        : ""}
                                                    <div className="options">
                                                        <div className="addmoreimage addmoreimage-big" onClick={() => this.addfun("options")}>
                                                            {" "}
                                                            <i className="fa fa-plus" /> Add{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {this.deleteAlert()}
                        </div>
                    </div>
                    <div className="field">
                        <div className="option option-checkbox">
                            {this.state.fieldprops.properties.options ? (
                                <ul className="clear">
                                    {" "}
                                    {this.state.fieldprops.properties.options.map(
                                        function(value, index) {
                                            return (
                                                <li>
                                                    {" "}
                                                    {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="checkbox" />}{" "}
                                                    <img src={value.label_image} /> {value.label}
                                                    <input type='text' style={{marginRight:'10%',float:'right',height:24,width:80, marginTop: "9px" }}></input>
                                                   
                                                    <div className="parent-of-child-class clear">
                                                        {value.sublabel && value.sublabel instanceof Array
                                                            ? value.sublabel.map(
                                                                function(subval, key) {
                                                                    return (
                                                                        <div>
                                                                            {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="radio" />}{" "}
                                                                            <img src={subval.label_image} /> {subval.sublabel}
                                                                        </div>
                                                                    );
                                                                }.bind(this)
                                                            )
                                                            : ""}
                                                    </div>
                                                </li>
                                            );
                                        }.bind(this)
                                    )}
                                    {this.state.fieldprops.properties.other === 1 ? (
                                        <li>
                                            {this.state.fieldprops.properties.choice_type === "single" ? <input name="choice" type="radio" /> : <input type="checkbox" />}
                                            Others
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );







        const scalequestion = (
            <div>
                <h4 className="labelheading">{this.state.fieldprops.label}</h4>
                <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                    <div className="topbar">
                        <div className="label">
                            <Textarea minRows={1} maxRows={10} minColumns={1} maxColumns={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" style={{ resize:"none" }} />
                            {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                      
                            
                        {/* {this.upArrow()}
                        {this.downArrow()} */}
                        {/* <h6 style={{float:'right',flexDirection:"row",marginTop: "-30px",marginRight:"40px"}}>Weight</h6> */}
                        </div>
                        <div className="actions">
              <span onClick={() => this.handleClickOpen("open2")}>
                <i className="fa fa-cog" />
              </span>

                            <div className={boardval}>
                                <div className="properties-main-header clear">
                                    <p>Muliple Select question Properties</p>
                                    <i class="fa fa-close" onClick={() => this.handleClose("open1")} /> 
                                </div>
                                <div className="properties-body-tab">
                                    <ul>
                                        <li>
                                            <h3>Question Text</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />
                                                <label> Type your questions </label>
                                            </div>
                                        </li>
                                        <li>
                                            <h3>Name</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                                <label> Edit your Name </label>
                                            </div>
                                        </li>

                                        <li>
                                            <h3>Sub Heading</h3>
                                            <div className="below-lanbel-body">
                                                <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                                <label> Add a sub heading below main title. </label>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                    </div>
                                                </div>
                                                <label> Prevent submission if this question is empty. </label>
                                            </div>
                                        </li>
										 */}
										{/* <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">No Return</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <div class="switch-textboxes xtboxestext">Display Other Option</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimit checked={this.state.fieldprops.properties.other} onChange={this.handleChange("other")} value="other" color="primary" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        {/* <li>
                                            <div className="below-lanbel-body">
                                                <div className="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Choice Type</h3>
                                                    <div className="twocol">
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="single" checked={this.state.fieldprops.properties.choice_type === "single"} onChange={this.radioChange} />
                                                                <label>Single</label>
                                                            </div>
                                                        </div>
                                                        <div class="switch-textboxes">
                                                            <div className="radio-box">
                                                                <input type="radio" value="multiple" checked={this.state.fieldprops.properties.choice_type === "multiple"} onChange={this.radioChange} />
                                                                <label>Multiple</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}

                                        <li>
                                            <div className="below-lanbel-body">
                                                <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                    <h3>Options</h3>

                                                    {/* <div class="switch-textboxes xtboxestext">Multilevel</div>
                                                    <div class="switches-boxes">
                                                        <Switchlimittwo checked={this.state.fieldprops.properties.multilevel} onChange={this.handleChange("multilevel")} value="multilevel" color="primary" />
                                                    </div> */}
                                                    <div className="clearfix" />
                                                    {this.state.fieldprops.properties.options
                                                        ? this.state.fieldprops.properties.options.map(
                                                            function(value, index) {
                                                                return (
                                                                    <div className="twocol dropboxer">
                                                                        <div className="dropper dropper-aletr-div">
                                                                            <div className="parentlabel clear clearfix">
                                                                                <span>{alphabet[index]}</span>
                                                                                <input
                                                                                    type="text"
                                                                                    name="name"
                                                                                    class="mediumfm"
                                                                                    value={this.state.fieldprops.properties.options[index].label}
                                                                                    onChange={e => this.updateprops(e, "label", index)}
                                                                                />

                                                                                <div className="wrap-upload-delete">
                                                                                    <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "parenlabel_image", index, "")} />
                                                                                    <div className="addimgs">
                                                                                        <i class="fa fa-trash" onClick={() => this.deletefun(index, "parentlabel")} />
                                                                                    </div>
                                                                                    <img src={value.label_image} width="50" />
                                                                                </div>
                                                                            </div>

                                                                            {this.state.fieldprops.properties.multilevel === 1 ? (
                                                                                <div className="sublabel">
                                                                           
                                                                                    {this.state.fieldprops.properties.options && this.state.fieldprops.properties.options[index].sublabel &&
																					this.state.fieldprops.properties.options[index].sublabel instanceof Array
                                                                                        ? this.state.fieldprops.properties.options[index].sublabel.map(
                                                                                            function(value, key) {
                                                                                                return (
                                                                                                    <div className="clear clearfix">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="name"
                                                                                                            class="mediumfm"
                                                                                                            value={this.state.fieldprops.properties.options[index].sublabel[key].sublabel}
                                                                                                            onChange={e => this.updateprops(e, "childlabel", index, key)}
                                                                                                        />

                                                                                                        <div className="wrap-upload-delete">
                                                                                                            <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "sublabel_image", index, key)} />
                                                                                                            <div className="addimgs">
                                                                                                                <i class="fa fa-trash" onClick={() => this.deletefun(index, "childlabel", key)} />
                                                                                                            </div>
                                                                                                            <img src={value.label_image} width="50" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            }.bind(this)
                                                                                        )
                                                                                        : 																						
																						""}

                                                                                    <div className="addmoreimage" onClick={() => this.addfun("suboptions", index)}>
                                                                                        {" "}
                                                                                        <i className="fa fa-plus" /> Add{" "}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }.bind(this)
                                                        )
                                                        : ""}
                                                    <div className="options">
                                                        <div className="addmoreimage addmoreimage-big" onClick={() => this.addfun("options")}>
                                                            {" "}
                                                            <i className="fa fa-plus" /> Add{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {this.deleteAlert()}
                        </div>
                    </div>
                    <div className="field">
                        <div className="option option-checkbox">
                            {this.state.fieldprops.properties.options ? (
                                
                                <ul className="clear">
                                    {" "}
                                   <li>
                                    <label style={{width:'15%',float:'right', textAlign: "center" }}>BAD</label>
                                    <label style={{width:'15%',float:'right', textAlign: "center"}}>OK</label>
                                    <label style={{width:'15%',float:'right', textAlign: "center"}}>GOOD</label>
                                   </li>
                                    {this.state.fieldprops.properties.options.map(
                                        function(value, index) {
                                            return (
                                                <li>
                                                    {" "}
                                                    
                                                   {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="radio" disabled />}{" "}
                                                    <img src={value.label_image} /> {value.label}
                                                    {/* <input type='text' style={{marginRight:'10%',float:'right',height:20,width:80}}></input> */}
                                                
                                                 <label style={{width:'15%',float:'right',textAlign: "center"}}><input type="checkbox"></input></label>
                                                 <label style={{width:'15%',float:'right',textAlign: "center"}}><input type="checkbox"></input></label>
                                                 <label style={{width:'15%',float:'right',textAlign: "center"}}><input type="checkbox"></input></label>
                                                    
                                                     <div className="parent-of-child-class clear">
                                                        {value.sublabel && value.sublabel instanceof Array
                                                            ? value.sublabel.map(
                                                                function(subval, key) {
                                                                    return (
                                                                        <div>
                                                                            {this.state.fieldprops.properties.choice_type === "multiple" ? <input type="checkbox" /> : <input name="choice" type="radio"/>}{" "}
                                                                            <img src={subval.label_image} /> {subval.sublabel}
                                                                        </div>
                                                                    );
                                                                }.bind(this)
                                                            )
                                                            : ""}
                                                    </div>
                                                </li>
                                            );
                                        }.bind(this)
                                    )}
                                    {this.state.fieldprops.properties.other === 1 ? (
                                        <li>
                                            {this.state.fieldprops.properties.choice_type === "single" ? <input name="choice" type="radio" /> : <input type="checkbox" />}
                                            Others
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
            
        );
           
        
        const dropdown = (
            <div>
            <h4 className="labelheading">{this.state.fieldprops.label}</h4>
            <div className="bdrop" onDoubleClick={() => this.handleClickOpen("open2")}>
                <div className="topbar">
                    <div className="label">
                           <Textarea  minRows={1} maxRows={10} onFocus={this.handleFocus} value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} className="fullwidth" style={{ resize:"none"}} />
                        {this.state.fieldprops.properties.subheading ? <h6 className="subheading">{this.state.fieldprops.properties.subheading}</h6> : ""}
                     
                      {/* {this.upArrow()}
                    {this.downArrow()} */}
                    <div style={{display:'flex',width:'100%'}}>
                   <h6 style={{flexDirection:"row",top: "14px",right:"13%", position: 'absolute'}}>Weight</h6>
                   </div>
                    </div>
                    <div className="actions">
          <span onClick={() => this.handleClickOpen("open2")}>
            <i className="fa fa-cog" />
          </span>

                        <div className={boardval}>

                            <div className="properties-main-header clear">
                                <p>Dropdown question Properties</p>
                                <i class="fa fa-close" onClick={() => this.handleClose("open1")} />
                            </div>
                            <div className="properties-body-tab">
                                <ul>
                                    <li>
                                        <h3>Question Text</h3>
                                        <div className="below-lanbel-body">
                                            <input type="text" name="name" class="mediumfm" value={this.state.fieldprops.properties.question} onChange={e => this.updateprops(e, "question")} />
                                            <label> Type your questions </label>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Name</h3>
                                        <div className="below-lanbel-body">
                                            <input type="text" name="name" className="mediumfm" value={this.state.fieldprops.label} onChange={e => this.updateprops(e, "inputtypename")} />
                                            <label> Edit your Name </label>
                                        </div>
                                    </li>

                                    <li>
                                        <h3>Sub Heading</h3>
                                        <div className="below-lanbel-body">
                                            <input type="text" name="name" value={this.state.fieldprops.properties.subheading} class="mediumfm" onChange={e => this.updateprops(e, "subheading")} />
                                            <label> Add a sub heading below main title. </label>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="below-lanbel-body">
                                            <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                <div class="switch-textboxes xtboxestext">Mandatory</div>
                                                <div class="switches-boxes">
                                                    <Switchlimit checked={this.state.fieldprops.properties.mandatory} onChange={this.handleChange("mandatory")} value="mandatory" color="primary" />
                                                </div>
                                            </div>
                                            <label> Prevent submission if this question is empty. </label>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="below-lanbel-body">
                                            <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                <div class="switch-textboxes xtboxestext">No Return</div>
                                                <div class="switches-boxes">
                                                    <Switchlimit checked={this.state.fieldprops.properties.noreturn} onChange={this.handleChange("noreturn")} value="noreturn" color="primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="below-lanbel-body">
                                            <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                <div class="switch-textboxes xtboxestext">Display Other Option</div>
                                                <div class="switches-boxes">
                                                    <Switchlimit checked={this.state.fieldprops.properties.other} onChange={this.handleChange("other")} value="other" color="primary" />
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}

                                    {/* <li>
                                        <div className="below-lanbel-body">
                                            <div className="switch-text-boxes switch-text-boxes-mandatory clear">
                                                <h3>Choice Type</h3>
                                                <div className="twocol">
                                                    <div class="switch-textboxes">
                                                        <div className="radio-box">
                                                            <input type="radio" value="single" checked={this.state.fieldprops.properties.choice_type === "single"} onChange={this.radioChange} />
                                                            <label>Single</label>
                                                        </div>
                                                    </div>
                                                    <div class="switch-textboxes">
                                                        <div className="radio-box">
                                                            <input type="radio" value="multiple" checked={this.state.fieldprops.properties.choice_type === "multiple"} onChange={this.radioChange} />
                                                            <label>Multiple</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}

                                    <li>
                                        <div className="below-lanbel-body">
                                            <div class="switch-text-boxes switch-text-boxes-mandatory clear">
                                                <h3>Options</h3>

                                                {/* <div class="switch-textboxes xtboxestext">Multilevel</div> */}
                                                {/* <div class="switches-boxes">
                                                    <Switchlimittwo checked={this.state.fieldprops.properties.multilevel} onChange={this.handleChange("multilevel")} value="multilevel" color="primary" />
                                                </div> */}
                                                <div className="clearfix" />
                                                {this.state.fieldprops.properties.options
                                                    ? this.state.fieldprops.properties.options.map(
                                                        function(value, index) {
                                                            return (
                                                                <div className="twocol dropboxer">
                                                                    <div className="dropper dropper-aletr-div">
                                                                        <div className="parentlabel clear clearfix">
                                                                            <span>{alphabet[index]}</span>
                                                                            <input
                                                                                type="text"
                                                                                name="name"
                                                                                class="mediumfm"
                                                                                value={this.state.fieldprops.properties.options[index].label}
                                                                                onChange={e => this.updateprops(e, "label", index)}
                                                                            />

                                                                            <div className="wrap-upload-delete">
                                                                                {/* <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "parenlabel_image", index, "")} /> */}
                                                                                <div className="addimgs">
                                                                                    <i class="fa fa-trash" onClick={() => this.deletefun(index, "parentlabel")} />
                                                                                </div>
                                                                                <img src={value.label_image} width="50" />
                                                                            </div>
                                                                        </div>
{/* 
                                                                        {this.state.fieldprops.properties.multilevel === 1 ? (
                                                                            <div className="sublabel">
                                                                       
                                                                                {this.state.fieldprops.properties.options && this.state.fieldprops.properties.options[index].sublabel &&
                                                                                this.state.fieldprops.properties.options[index].sublabel instanceof Array
                                                                                    ? this.state.fieldprops.properties.options[index].sublabel.map(
                                                                                        function(value, key) {
                                                                                            return (
                                                                                                <div className="clear clearfix">
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="name"
                                                                                                        class="mediumfm"
                                                                                                        value={this.state.fieldprops.properties.options[index].sublabel[key].sublabel}
                                                                                                        onChange={e => this.updateprops(e, "childlabel", index, key)}
                                                                                                    />

                                                                                                    <div className="wrap-upload-delete">
                                                                                                        <StyledDropZone label="upload" onDrop={this.onDrop.bind(this, "sublabel_image", index, key)} />
                                                                                                        <div className="addimgs">
                                                                                                            <i class="fa fa-trash" onClick={() => this.deletefun(index, "childlabel", key)} />
                                                                                                        </div>
                                                                                                        <img src={value.label_image} width="50" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }.bind(this)
                                                                                    )
                                                                                    : 																						
                                                                                    ""}

                                                                                <div className="addmoreimage" onClick={() => this.addfun("suboptions", index)}>
                                                                                    {" "}
                                                                                    <i className="fa fa-plus" /> Add{" "}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )} */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }.bind(this)
                                                    )
                                                    : ""}
                                                <div className="options">
                                                    <div className="addmoreimage addmoreimage-big" onClick={() => this.addfun("options")}>
                                                        {" "}
                                                        <i className="fa fa-plus" /> Add{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {this.deleteAlert()}
                    </div>
                </div>
                <div className="field">
                    <div className="option option-checkbox">
                   
                    <select style={{height:30,width:200}}  >
                    <option>Select your option</option>

                  {this.state.fieldprops.properties.options ? 
                    
                       
                      
                                
                                this.state.fieldprops.properties.options.map((value)=>
                                    
                                                
                                                
                                        <option> {value.label }
                                        
                                        
                                        </option>
                                        
                                                
                                             
                      
     ) : (
        ""
    )} 

    </select>
                    </div>
                </div>

            </div>
        </div>
    );




   
 const type = this.props.type;

        const {
            text,
            isDragging,
            connectDragSource,
            connectDropTarget,
        } = this.props
        const opacity = isDragging ? 0 : 1

        return (
		<div>{this.state.open === true ? (
           
                <div style={Object.assign({}, style, { opacity })}>
                {
                     type === "input"
                        ? inputbox
                             : type === "Multiple Select"
                                    ? multipleselect
                                    : type === "Scale Question"
                                        ? scalequestion
                                    : type === "Single Select"
                                        ? singleselect
                                         : type === "Date Input"
                                             ? date
                                             : type === "dropdown"
                                                    ? dropdown
                                                    : false}
            </div>
           
        ) :(
            connectDragSource(
            connectDropTarget(
                <div style={Object.assign({}, style, { opacity })}>
                { type === "input"
                        ? inputbox
                        : type === "Multiple Select"
                                    ? multipleselect
                                    : type === "Scale Question"
                                       ? scalequestion
                                       : type === "Single Select"
                                          ? singleselect
                                          : type ==="Date Input"
                                             ? date 
                                            : type === "dropdown"
                                                    ? dropdown
                                                            : false}
            </div>
            ), )
        )
        }
		
		<Snackbar
            place="bc"
            color={msgColor}
            open={br}
            message={message}
            closeNotification={() => this.setState({ br: false })}
            close
          /></div>
         
 );
    }
}

Card.propTypes = {
    color: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired
};

export default DropTarget('card', cardTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
}))(
    DragSource('card', cardSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }))(Card),
)