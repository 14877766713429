import React from "react";
import { DragSource } from "react-dnd";
import { PropTypes } from "prop-types";
import { ITEM } from "./itemTypes";

const Source = ({ color, icon, text, connectDragSource, isDragging, type }) =>
  connectDragSource(
    <div
      className="board__sources__source"
      style={{
        opacity: isDragging ? 0.25 : 1
      }}
    >
      <i className={icon} />
      <p> {text} </p>
    </div>
  );

Source.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired
};

const source = {
  beginDrag(props) {

    const { color } = props;
    const { text } = props;
    const { type } = props;
    return {
      color,
      text,
      type
    };
  },
  endDrag(props, monitor, index) {

    if (!monitor.didDrop()) {
      return;
    }
    const { onDrop } = props;
    const { text } = props;
    const { type } = props;
    const  question  = "";
    const { dropChange } = props;
    const { question_id } = props;
    const  handler  = new Date().valueOf();

   
    const  inputlabel  = "Text Input " + props.labelval ;
    const  singleselectlabel  = "Single Select Questions " + props.labelval ;
    const  multipleselectlabel  = "Multiple Select Questions " + props.labelval ;
    const datelabel = "Date&Time Input " + props.labelval;
    const scalequestionlabel = "Scale/Matrix Question" + props.labelval;
    const dropdownlabel ="Dropdown" + props.labelval;
    const  label  =  type === 'input' ? (inputlabel)
                   : type === 'Single Select' ? (singleselectlabel)
                   : type === 'Multiple Select' ? (multipleselectlabel)
                   : type === 'Scale Question' ? (scalequestionlabel)
                   : type === 'Date Input' ? (datelabel)
                   : type==="dropdown" ? (dropdownlabel)
                   : false
    const inputprops = {
          question:"Type a question",
          length: "20"
    }
    
    const singleselectprops = {
          question:"Type ad question",
        choice_type:"Single Select"
    }
    
    const multipleselectprops = {
      question:"Type a question",
      choice_type:"Multiple Select"
    }

     const dateprops = {
       question:"Type a question"
    }

    const scalequestionprops = {
       question:"Type a question",
      choice_type:"Scale Question"
    }
   
      const dropdownprops = {
        question:"Select a list",
        dropdown_type:"select a option"
    }
   

    const conditions = []
    const  properties  =  type === 'input' ? (inputprops)
                        : type === 'Single Select' ? (singleselectprops)
                        : type === 'Multiple Select' ? (multipleselectprops)
                        : type === 'Date Input' ? (dateprops)
                        : type === 'Scale Question'?(scalequestionprops)
                        : type==="dropdown" ? (dropdownprops)
                        : false

    const { color } = monitor.getItem();
    const { shape } = monitor.getDropResult();
    onDrop(color, shape, text, type, dropChange, handler, question, properties, question_id, label, conditions);
  }
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

export default DragSource(ITEM, source, collect)(Source);
