/**
 * @CreateSurvey handles creating a custom survey page.
 *
 * @author
 */

import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// Survey Pages
import SurveyInfo from "./SurveyInfo/SurveyInfo";
import SurveyBuilder from "./SurveyBuilder/SurveyBuilder";
import "./CreateSurvey.css";
import Previewsurvey from "./preview";
import Settings from "./settings";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#074e9e"
        }
    }
});

const styles = theme => ({
    root: {
        width: "100%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    },
    card: {
        minWidth: "90%"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        marginBottom: 16,
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    stepIcon: {
        color: "#c71585"
    },
    checked: {}
});

/**
 * gets the steps of the views.
 *
 * @public
 * @returns {string} [Survey Info], [Form Builder], [Preview]
 */
function getSteps() {
    //return ["Survey Info", "Form Builder", "Conditions", "Preview"];
	 return ["Survey Info", "Form Builder", "Conditions"];
}

const text={};
text.conditions=[];
text.handler=1575617682763;
text.label="Text Input 1"
text.properties={question:"Type a question",length:20}
text.question_id=4
text.type="input"





const single={};
single.conditions=[];
single.handler=1575614107543;
single.label= "Single Select Questions 1";
single.properties={}
single.question_id= 3
single.rightStatus= true
single.type= "Single Select"
single.properties.choicetype=" "
single.properties.options=[]
single.properties.question="Which is your favorite fruit ?"
single.properties.options=[{id:0,label:"Orange",label_image:""},{id:1,label:"Apple",label_image:""},
{id:2,label:"Strawberry",label_image:""}]
console.log(single)

const date={};
date.conditions=[];
date.handler=1576216492410;
date.label= "Date&Time Input 1";
date.properties={}
date.properties.question="Select the date for appoinment?";
date.question_id=51;
date.rightStatus=false;
date.type="Date Input";


const multiple={};
multiple.conditions=[];
multiple.handler=1575614107543;
multiple.label= "Multiple Select Questions 1";
multiple.properties={}
multiple.question_id= 3
multiple.rightStatus= true
multiple.type= "Multiple Select"
multiple.properties.choicetype=" "
multiple.properties.options=[]
multiple.properties.question="Which is your favorite pets ?"
multiple.properties.options=[{id:0,label:"Dog",label_image:""},{id:1,label:"Cat",label_image:""},
{id:2,label:"Lion",label_image:""}]
console.log(multiple)

const scalequestion={}
scalequestion.conditions=[];
scalequestion.handler=1576126828143;
scalequestion.label="Scale/Matrix Question 1";
scalequestion.properties={}
scalequestion.properties.question="How do you rate the following?";
scalequestion.choicetype="Scale Question";
scalequestion.options=[];
scalequestion.properties.options=[{id:0,label:"Service"},{id:1,label:"Quality"}]
scalequestion.question_id=0;
scalequestion.rightStatus=false;
scalequestion.type= "Scale Question";



const drop={};
drop.conditions=[];
drop.handler=1575618471798;
drop.label="Dropdown 1";
drop.properties={}
drop.properties.question="What is your favourite vechile for travelling?";
//drop.dropdown_type= "select a option";
drop.properties.options=[{id:0,label:"Bike",label_image:""},
             {id:1,label:"Car",label_image:""},
             {id:2,label:"Bus",label_image:""}]
drop.question_id= "5"
drop.rightStatus= true
drop.type="dropdown"
console.log(drop)





const condProp1 = {};
condProp1.gps_stats = "show";
condProp1.question = "gps";
const conditionContent1 = [];
const second = {};
second.conditions = [];
second.handler = 1571229124782;
second.label = "GPS 1";
second.properties = condProp1;
second.question = "test";
second.question_id = 2;
second.rightStatus = false;
second.type = "gps2";
conditionContent1.push(second);
const conditiontarget1 = {};
conditiontarget1.do = "hide";
conditiontarget1.handler =  1571229122727;
const conditionsource1 = [];
conditionsource1.handler = 1571229120030;
conditionsource1.source_type = "input";
conditionsource1.state = "empty";
const conditionsarray = [];
conditionsarray.condtion_id = 0;
conditionsarray.rule = "any";
conditionsarray.source = conditionsource1;
conditionsarray.target = conditiontarget1;
const secondarray = {};
secondarray.author = "admin";
secondarray.conditions = [conditionsarray];
secondarray.content = conditionContent1;
secondarray.id = 941;
secondarray.modified = "Thu, 17 Oct 2019 03:24:29 GMT";
secondarray.platform_type = "App & Browser";
secondarray.published = "Thu, 17 Oct 2019 08:54:26 GMT";
secondarray.questions = 0;
secondarray.responses = 0;
secondarray.survey_minutes = 0;
secondarray.survey_points = 0;
secondarray.tags = "";
secondarray.title = "GPS Test";
secondarray.type = "all";


const condProp = {};
condProp.gps_stats = "show";
condProp.question = "gps";
const conditionContent = [];
const first = {};
first.conditions = [];
first.handler = 1571229124782;
first.label = "GPS 1";
first.properties = condProp;
first.question = "gfd";
first.question_id = 2;
first.rightStatus = false;
first.type = "gps";



//conditionContent.push(first);
conditionContent.push(text,date,drop,single,multiple,scalequestion);


const conditiontarget = {};
conditiontarget.do = "hide";
conditiontarget.handler = 1571229122727;
const conditionsource = [];
conditionsource.handler = 1571229120030;
conditionsource.source_type = "input";
conditionsource.state = "empty";
const conditionsarray1 = [];
conditionsarray1.condtion_id = 0;
conditionsarray1.rule = "any";
conditionsarray1.source = conditionsource;
conditionsarray1.target = conditiontarget;

const firstarray = {};
firstarray.author = "admin";
firstarray.conditions = [conditionsarray1];
firstarray.content = conditionContent;
// firstarray.content = single;
firstarray.id = 942;
firstarray.modified = "Thu, 17 Oct 2019 03:24:29 GMT";
firstarray.platform_type = "App & Browser";
firstarray.published = "Thu, 17 Oct 2019 08:54:26 GMT";
firstarray.questions = 0;
firstarray.responses = 0;
firstarray.survey_minutes = 0;
firstarray.survey_points = 0;
firstarray.tags = "";
firstarray.title = "TextFieldTest";
firstarray.type = "all";
const mainAarray = [];
mainAarray.push(firstarray);
console.log(mainAarray,"test")




/**
 * creates a survey view which is to get the customizable user input and exposes a `@public` method.
 */
class CreateSurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: "",
            tags: "",
            points: 0,
            minutes: 0,
            project_id: "",
            mission_id: "",
            saveform: false,
            selectedValue: "all",
            redirect: false,
            drops: [],
            conditions: [],
            lopen: false,
            ropen: false,
            id: "",
            validate:0,
            loading: 0,
            qid: 0,
            draftid:0,
            updatedate:"",
			platformType:"",
			isAssigned: "",
			      // snackbar props
      msgColor: "info",
      message: "",
      br: false
        };
        this.props.handleCollapseScreen(false);
        this.elementsbar = this.showAddElements.bind(this);
        this.settingsbar = this.settingsOptions.bind(this);
        this.addCondtions = this.addCondtions.bind(this);
		this.ShowNotification = this.ShowNotification.bind(this);
    }


    /**
     * clearing set interval
     */
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /**
     * loads old datas from api
     */
    componentDidMount() {
        //this.interval = setInterval(() => this.handleOnClick2("draft", "auto"), 20000)
        console.log("rrrrrrrrrrrrr")
        // debugger

        if (this.props.match.params.id) {
            const id = parseInt(this.props.match.params.id)

            
            mainAarray.map((main, index) => {

                if(main.id === id){
                    console.log(main);
					let text = JSON.stringify( main );
					console.log(text);
                    this.setState({
                        name: mainAarray[index].title,
                        tags: mainAarray[index].tags,
                        points: mainAarray[index].survey_points,
                        selectedValue:mainAarray[index].type,
                        drops: mainAarray[index].content,
                        conditions: mainAarray[index].conditions,
                        id: mainAarray[index].id,
                        draftid: mainAarray[index].id,
                        minutes:mainAarray[index].survey_minutes,
                         platformType:mainAarray[index].platform_type,
                        //  isAssigned: resp.data.isAssigned ? resp.data.isAssigned : ""
                    });


                }
            });

          
        
        }
    }
	
	handlePlatformType = event => {
          
        this.setState({
        platformType  : event.value
        });
    
        
      }; 
	  
	  ShowNotification = (msg, color) => {
    this.setState({
      message: msg,
      msgColor: color,
      //   br:true
    });

    let place = "br";
    var x = [];
    x[place] = true;
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        x[place] = false;
        this.setState(x);
      }.bind(this),
      3000
    );
  }

    /**
     * Sets the input element.
     *
     */
    settingsOptions(e) {
        if (e === "open2") {
            this.setState({
                ropen: true
            });
        } else if (e === "open1") {
            this.setState({
                ropen: false
            });
        }
    }

    /**
     * handle property bar
     *
     */
    showAddElements() {
        this.setState({
            lopen: !this.state.lopen
        });
    }

    /**
     * listen to changes on drop
     */
    dropChange = i => {
        this.setState({
            drops: i
        });
    };

    deleteDrops = i => {

        this.setState({
            drops: i
        }, this.autoSave);
    };

    /**
     * handle next button
     *
     */
    handleNext = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep + 1,
            lopen: false,
            ropen: false
        });
    };

    /**
     * handle next button of condition page
     */
    handleNext2 = (dname) => {
		console.log('praga chking here')
		console.log(this.state);
		//console.log(mainAarray);
		const formdata = {
		  formdata: this.state.drops,
		  survey_name: this.state.name,
		  survey_tags: this.state.tags,
		  survey_points: this.state.points,
		  json_ref_data: this.state, 
		};
		axios.defaults.headers.common['Authorization'] = "Bearer " + 'very_long_bearer_token';
		 axios.post('http://facileinfotech.com/demo/json_forms/public/api/push_data_formjson', { formdata })
      .then(res => {
        console.log(res);
        console.log(res.data);
		this.setState({
            redirect: true, 
        });
		
		//axios.post('http://facileinfotech.com/demo/json_forms/public/api/push_data_formjson', { formdata })
		//axios.post('http://localhost/projects/json_forms/public/api/push_data_formjson', { formdata })
      })
		
		console.log(this.state.persons);
        /**/


      
    
    
    
    };

    /**
     * handle back button
     *
     */
    handleBack = () => {
        const { activeStep } = this.state;
        if (activeStep === 0) {
            this.setState({ redirect: true });
        } else {
            this.setState({
                activeStep: activeStep - 1
            });
        }
    };

    /**
     * handle back button on conditions page
     *
     */



    handleBack2 = () => {
        const { activeStep } = this.state;
        const { conditions, drops } = this.state;

        drops.map((drop, i) => (drop.conditions = []));
        conditions.map((condtion, index) =>
            drops.map(
                (drop, i) => (
                    condtion.source.handler === drop.handler
                        ? drop.conditions.push(condtion)
                        : false
                )
            )
        );
        if (activeStep === 0) {
            this.setState({ redirect: true });
        } else {
            this.setState({
                activeStep: activeStep - 1,
                drops
            });
        }
    };

    /**
     * handles points and minutes
     *
     */
    handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        if (name === "points" || name === "minutes") value = parseInt(value);

        this.setState({
            [name]: value
        });
    };

    /**
     * handle project change
     *
     */
    handleProjectChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * set selected value
     *
     */
    handleqtypeChange = event => {
        const value = event.target.value;
        this.setState({
            selectedValue: value
        });
    };

    /**
     * check the survey existance
     *
     */
    handleOnClick = (dname) => {
		this.handleNext2(dname);        
    };


    /**
     * check the survey existance with autosave
     *
     */
    handleOnClick2 = (dname, dauto) => {

        this.state.draftid ? this.updateSurvey(dname, dauto) : this.addSurvey(dname, dauto);
    };

    /**
     * add condtions from conditon componenet
     *
     */
    addCondtions(event) {
        this.setState({
            conditions: event
        });
    }

    /**
     * Add new survey to databse
     *
     */
    addSurvey(dname, dauto) {

       
       
    }

    /**
     * update current survey to databse
     *
     */
    updateSurvey(dname, dauto) {

        
    }

    autoSave = () => {
		this.handleOnClick2("draft", "auto")		
    }

    render() {

        const validater = this.state.activeStep === 0 ? this.state.name === "" : this.state.drops.length === 0;
		
		 const platformType=this.state.platformType;

        if (this.state.redirect) {
            return <Redirect push to="/home/surveys" />;
        }

        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep, id } = this.state;
        const previewname = this.state.name;
        const qtype = this.state.selectedValue;
        const prevtags = this.state.tags;
        const points = this.state.points;
        const loading = this.state.loading;
        const previewdrops = this.state.drops;
        const previewconditions = this.state.conditions;
        const qid = this.state.qid;
        const validate = this.state.validate
        const minutes = this.state.minutes
		const isAssigned = this.state.isAssigned

        /**
         * gets the step contents to perform next and previous actions.
         *
         * @public
         * @param {Number} [stepIndex]
         * @param {string} [handleInputChange]
		 * @param {string} [handlePlatformType]
         * @param {string} [handleqtypeChange]
         * @param {string} [dropChange]
         * @param {string} [elemetsbar]
         * @param {string} [settingsbar]
		 * 
         */
        function getStepContent(
            stepIndex,
            handleInputChange,
            handleProjectChange,
			handlePlatformType,
            handleqtypeChange,
            dropChange,
            deleteDrops,
            autoSave,
            elemetsbar,
            settingsbar,
            addCondtions
        ) {
            switch (stepIndex) {
                case 0:
                    return (
                        <SurveyInfo
                            name="1"
                            handleInputChange={handleInputChange}
                            handleqtypeChange={handleqtypeChange}
                            handleprojects={handleProjectChange}
							handlePlatformType={handlePlatformType}
                            surveyname={previewname}
                            qtype={qtype}
                            prevtags={prevtags}
                            points={points}
                            minutes={minutes}
							 platformType={platformType}
							 isAssigned={isAssigned}
                        />
                    );
                case 1:
                    return (
                        <SurveyBuilder
                            name="2"
                            dropChange={dropChange}
                            deletedrops={deleteDrops}
                            olddrops={previewdrops}
                            lchange={elemetsbar}
                            rchange={settingsbar}
                            id={id}
                            oldconditions={previewconditions}
                            autosave = {autoSave}
							 platformType={platformType}
                        />
                    );
                case 2:
                    return <Settings
                        drops={previewdrops}
                        getCondtions={addCondtions}
                        oldconditions={previewconditions}
                        autosave = {autoSave}
                    />;
					{/*case 3:
                    return (
                        <Previewsurvey
                            surveyname={previewname}
                            suveyfield={previewdrops}
                            suveyconditions={previewconditions}
                            qtype={qtype}
                            prevtags={prevtags}
                            id={id}
                            loading={loading}
                        />
                    ); */}
                default:
                    return "Preview Page";
            }
        }

        const body_class = this.props.fullWidth ? "body-form body-form-expanded" : "body-form body-form-collapsed";

        const { lopen, ropen } = this.state;
        const lefttoggle = lopen ? " leftopen" : " leftclose";
        const righttoggle = ropen ? " rightopen" : " rightclose";
        const fullwidth = this.state.activeStep !== 1 ? " fullwidth" : " shrink";
        const update = this.state.id ? "Update" : "Finish";
		const { msgColor, br, message } = this.state;


        return (
            <MuiThemeProvider theme={theme}>
              <div className={`builderwrap ${body_class}`}>
                <Card style={{ height: "100%" }} className={`additionalclassaddmulticard ${classes.card}${lefttoggle}${righttoggle}${fullwidth}`}>
                  <CardContent  className="buildercont">
                    <div className={`relativeposition ${classes.root}`} style={{ alignContent: "center" }}>
					{activeStep !=0 ? (
                        <div style={{position:"relative"}}>
                    <div className="stepersurveyname" 
                    style={{ textAlign: "center",
                    fontSize:"18px",
                    fontWeight:"bold",
					marginTop:"10px"
                     }}
                    >{previewname}</div>
                    <div
                    style={{ textAlign: "center",
                    fontSize: "14px",
                    color: "green",
                    fontWeight:"800"
                     }}
                    >
                    {this.state.updatedate != "" && 
						this.state.updatedate				
					}
                    </div>
                    </div>
                    )
                    : ("")                    
                    }
                      <Stepper activeStep={activeStep} alternativeLabel style={{ width: "100%" }}>
                          {steps.map(label => (
                              <Step key={label}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: { root: classes.stepIcon }
                                    }}>
                                    {label}
                                </StepLabel>
                              </Step>
                          ))}
                      </Stepper>
                      <div>
                          {this.state.activeStep === steps.length ? (
                              <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={this.handleReset}>Reset</Button>
                              </div>
                          ) : (
                              <div>
                                <div className={classes.instructions}>
                                    {getStepContent(
                                        activeStep,
                                        this.handleInputChange,
                                        this.handleProjectChange,
										this.handlePlatformType,
                                        this.handleqtypeChange,
                                        this.dropChange,
                                        this.deleteDrops,
                                        this.autoSave,
                                        this.elementsbar,
                                        this.settingsbar,
                                        this.addCondtions
                                    )}
                                </div>
                              </div>
                          )}
                      </div>
                    </div>
                  </CardContent>
                    { /*this.state.updatedate ? <div className="autosave">Auto Draft On : {this.state.updatedate}</div> :"" */}
                  <CardActions
                      style={{
                          bottom: 0,
                          backgroundColor: "#f1f1f1",
                          height: "10%",
                          position: "sticky"
                      }}>
                    <div style={{ width: "100%" }}>
                      <Button
                          style={{ float: "le   ft", left: 0 }}
                          onClick={activeStep === 2 ? this.handleBack2 : this.handleBack}
                          className={classes.backButton}  
                          variant="contained"
						  color="primary">
						
                          
                          
                        Back
                      </Button>
                        {(validate === 1) ? <div className="bottom-errormessageshow"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Kindly Fill or Delete the incomplete conditions</div> :""}
                        {/*(activeStep > 0) ?
                        <Button
                            name="draft"
							variant="contained"
							color="primary"
                            onClick={
                                () => this.handleOnClick("draft")
                            }>
                            Save as Draft
                        </Button>
                         :"" */}
                      <Button
                          style={{ float: "right", right: 0 }}
                          variant="contained"
                          color="primary"
                          disabled={validater}
                          onClick={
                              activeStep === steps.length - 1 ? this.handleOnClick : activeStep === 2 ? this.handleNext2 : this.handleNext
                          }>
                          {activeStep === steps.length - 1 ? update : "Next"}
                      </Button>
                    </div>
                  </CardActions>
                </Card>
				        <Snackbar
          place="br"
          color={msgColor}
          open={br}
          message={message}
          closeNotification={() => this.setState({ br: false })}
          close
        />
              </div>
            </MuiThemeProvider>
        );
    }
}

CreateSurvey.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(CreateSurvey);
