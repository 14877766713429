import React, { Component } from 'react';
import Modal from 'react-awesome-modal';

export default class Popupp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : false,
            textinput:'',
            value:'',
            sectionvalue:'',
            valuesection:'',
            drops:[],
           
          
        }
    }
    componentWillMount(){
        
        this.setState({
            drops:this.props.drops,
            visible:this.props.open
        })
    }

    componentWillReceiveProps (nextprops){
        this.setState({
            drops:nextprops.drops,
            visible:nextprops.open
        })
    }

    openModal() {
        this.setState({
            visible : true
        });
    }

    closeModal() {
        this.setState({
            visible : false
        });
        this.props.close(false)
    }
updatevalue=(e)=>{
this.setState({textinput:e.target.value})
}
update=()=>{
    let temp =this.state.value + "[" + this.state.valuesection + "]"

    this.setState({value:temp})
    //console.log(this.state.value)
}
updateformula =(e) =>{
    this.setState({value:e.target.value})
}
sectionupdate=(e)=>{
    this.setState({valuesection:e.target.value})
    //this.setState({sectionvalue:e.target.value})
}
    render() {
        
        return (
            <section>
                {/* <input type="button" value="Score" onClick={() => this.openModal()} /> */}
                <Modal 
                    visible={this.state.visible}
                    width="460"
                    height="300"
                    effect="fadeInUp"
                    onClickAway={() => this.closeModal()}
                    style={{borderRadius:"0"}}
                    
                >
                    <div>
                        <div class="topbar">
                            <h6 style={{padding:"15px 0 4px 4px", fontSize:"20px"}}>Score</h6>
                        </div>
                        <div className="row p-1">
                            <div className="col-sm-3 pl-4 pt-1">
                                Section
                            </div>
                            <div className="col-sm-6">
                                <select style={drop} onChange={this.sectionupdate} className="form-control">
                                    <option>Select section</option>
                                    {/* {this.state.drops.map((q,i)=>(
                                        q.choicetype ==="Scale Question" ?
                                        (q.properties.options.map((x,y)=>(
                                            <option id={i}>{q.label+ " : " + x.label}</option>
                                        ))) :
                                        <option id={i}>{q.label}</option>
                                    
                                    ))}
                                    */}
                                    {this.state.drops.map((e)=>
                                        <option>{e.label}</option>)}
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <button className="btn btn-primary btn-sm"  onClick={this.update}>Insert</button>
                            </div>
                        </div>
                        <div className="row p-1">
                            <div className="col-sm-3 pl-4 pt-1">
                                Question
                            </div>
                            <div className="col-sm-9">
                                <textarea style={inputbox} onChange={this.updatevalue} value={this.state.valuesection}  className="form-control"></textarea>
                            </div>
                        </div>
                        <div className="row p-1">
                            <div className="col-sm-3 pl-4 pt-1">
                                Description
                            </div>
                            <div className="col-sm-9">
                                <textarea style={inputbox} onChange={this.updateformula} value={this.state.value} className="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        );
    }
}
const drop={
    width:"100%",
}
const inputbox={
    height:"80px",
}